import React, { useState } from "react";
import "./Newsletter.css";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import userContext from "../../userContext";
import firebase from "firebase";

function Newsletter() {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");

  const userContextData = useContext(userContext);

  console.log("userContextData from newsletter", userContextData.chosenAnswer);

  const navigate = useNavigate();
  const addAnswer = (email) => {
    const userData = {
      email: email,
      answers: userContextData.chosenAnswer,
    };
    storeAnswerInFirebase(userData);
  };

  const storeAnswerInFirebase = (data) => {
    // Reference to the "work" object in Firebase Realtime Database
    const workRef = firebase.database().ref("quizData/busy");

    // Push the email into the "work" object
    workRef.push(
      {
        data: data,
        timestamp: firebase.database.ServerValue.TIMESTAMP,
      },
      (error) => {
        if (error) {
          console.error("Error storing answer in Firebase:", error);
        } else {
          console.log("Answer stored in Firebase");
        }
      }
    );
  };

  const clickHandler = () => {
    if (!validateEmail(email)) {
      setError("Please enter a valid email");
    } else {
      setError("");
      addAnswer(email);
      console.log(
        "userContextData from newsletter",
        userContextData.chosenAnswer
      );

      navigate("/hero");
    }
  };

  const validateEmail = (email) => {
    // Regular expression pattern for email validation
    const pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return pattern.test(email);
  };

  return (
    <div className="newsletter__container">
      <div className="newsletter__logo" />
      <div className="newsletter__content">
        <div className="newsletter__text">
          We have prepared a plan for you!{" "}
        </div>
        <div className="newsletter__innerContent">
          <span>
            This is a plan for you prepared by us. Send us your mail so that we
          </span>
          <span> can send you the plans best for you!</span>
        </div>

        <div className="newsletter__input">
          <input
            type="text"
            id="email"
            className="hlw"
            placeholder="✉️ Your email"
            value={email}
            onChange={(event) => setEmail(event.target.value)}
          />
        </div>
        {error && <div className="newsletter__error">{error}</div>}
        <div className="newsletter__button">
          <button onClick={clickHandler}>Continue</button>
        </div>
      </div>
    </div>
  );
}

export default Newsletter;
