import React from "react";
import "./Hero.css";
import FAQ from "../FAQ/Faq";
import TestinomialCard from "../Card/TestinomialCard";
import PayCard from "../Card/PayCard";
import NextCard from "../Card/NextCard";
import ThreeMonth from "../../assets/image/3month.png";
import year from "../../assets/image/yearly.png";
import sixMonth from "../../assets/image/6month.png";
import { useState } from "react";
import { useEffect } from "react";
import { useRef } from "react";
import firebase from "../../firebase.js";
import Footer from "../Footer/Footer";
import { useContext } from "react";
import userContext from "../../userContext";
import storeAnswerInFirebase from "../../utils/Helper/FirebaseStoreTrack.js";
import {
  generateRandomDoubleNumber,
  generateRandomNumber,
} from "../../utils/Helper/random.js";
import PlayStore from "../playstore/PlayStore.jsx";

const planArray = [
  {
    para1: "Enjoy an year without unnecessary phone calls",
    color: "#E6F5FA",
    textColor: "#00a3ff",
    buttonColor: "#00a3ff",
    background: "#14b4aa",
  },
  {
    para1: "Get a 6-month membership and half a year of no unwanted calls.",
    color: "#ffeff3",
    textColor: "#ff0000",
    buttonColor: "#ff0000",
    background: "linear-gradient(91deg, #4D94E7 7.84%, #2F7AD2 95.41%)",
  },
  {
    para1: "Do 3 months of disturbance free work for a very nominal amount ",
    color: "#dffff1",
    textColor: "#16c6b0",
    buttonColor: "#16c6b0",
    background: "#14b4aa",
  },
];
function Hero() {
  const [topPlansArray, setTopPlansArray] = useState([]);
  const userContextData = useContext(userContext);
  useEffect(() => {
    userContextData.addTrack("Plan page reached");
    async function fetchPlans() {
      const plansInfo = firebase.database().ref("plan_v2/NonIndianPlans");
      const snapshot = await plansInfo.once("value");
      const data = snapshot.val();

      const plansOrder = firebase.database().ref("planOrderForWeb");
      const snapshot2 = await plansOrder.once("value");
      const data2 = snapshot2.val();

      let count = 0;
      const updatedTopPlansArray = []; // Create a new array to store the updated plans
      const planIndexes = Object.values(data2);
      console.log(planIndexes);

      for (const index of planIndexes) {
        if (
          updatedTopPlansArray.length < 3 &&
          data[index] &&
          data[index].active === true
        ) {
          const plan = data[index];
          if (!plan.hasOwnProperty("highlighted")) {
            plan.highlighted = false;
            plan.recommended = false;
          }
          updatedTopPlansArray.push(plan);
        }
        if (updatedTopPlansArray.length === 3) {
          break;
        }
      }

      updatedTopPlansArray.map((topPlan, index) => {
        const planToAdd = planArray[index] || {};
        updatedTopPlansArray[index] = { ...topPlan, ...planToAdd };
      });

      const middleIndex = Math.floor(updatedTopPlansArray.length / 2);
      if (updatedTopPlansArray[middleIndex]) {
        updatedTopPlansArray[middleIndex].recommended = true;
      }

      setTopPlansArray(updatedTopPlansArray); // Update the state with the new array
    }

    fetchPlans();
  }, []);

  const userData = {
    tracks: userContextData.tracks,
  };
  if (userContextData.tracks.length !== 0) {
    storeAnswerInFirebase(userData, userContextData.sessionId);
    userContextData.removeTrack();
  }
  const paymentSectionRef = useRef(null);

  const handleSeePlansClick = () => {
    paymentSectionRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const percentage = generateRandomDoubleNumber(93.0, 95.0);
  const percentage1 = generateRandomNumber(95, 97);
  const noOfUser = generateRandomNumber(4500, 4600);

  return (
    <div className="hero__container">
      <div className="hero__main">
        <div className="hero__logo" />
        <div className="hero__content">
          <div className="hero__text">
            <h1 style={{ fontFamily: "Raleway" }}>Nextcaller</h1>
            <span className="hero__innerContent">
              Evaluating your answers, we calculated that after using
              Nextcaller, you will successfully overcome your frustration by
              <span style={{ color: "#4395F5" }}> {percentage}% </span> due to
              unwanted calls and you will be able to do your work smoothly and
              become more productive
            </span>
            <div className="hero__button">
              <button onClick={handleSeePlansClick}>See My Plans</button>
            </div>
          </div>
          <div className="hero__svg" />
        </div>
        <div className="hero__companies">
          <div className="hero__description">
            <span style={{ fontFamily: "Raleway" }}>
              <span style={{ color: "#4395F5" }}>{percentage1}% </span> of our
              users with similar results have successfully reduced their stress
              and increased their productivity
            </span>
          </div>
          <div className="hero__companiesLogo">
            <div className="hero__companyItem1" />
            <div className="hero__companyItem2" />
            <div className="hero__companyItem3" />
          </div>
        </div>

        <div className="hero__stats">
          <div className="hero__statText">
            <span style={{ fontFamily: "Raleway", fontSize: 44 }}>
              Hello <span style={{ color: "#4395F5" }}>User</span>
            </span>
            <span style={{ fontFamily: "Raleway", marginTop: 20 }}>
              <span style={{ color: "#4395F5" }}> Yay!</span> No more
              frustration due to disturbing calls.{" "}
              <span style={{ color: "#4395F5" }}>Nextcaller </span> has helped
              more <span style={{ fontWeight: "bold" }}> {noOfUser} </span>{" "}
              people like you to solve similar problems.
            </span>
            <div className="hero__button">
              <button onClick={handleSeePlansClick}>See My Plans</button>
            </div>
          </div>
          <div className="hero__statImage" />
        </div>
        <div className="hero__faq">
          <div className="hero__faqtext">
            <h1>FAQs</h1>
            <span>
              This might help you to understand more about the app and its
              features.
            </span>
          </div>
          <div className="hero__faqSection">
            <FAQ
              id="1"
              question={"What is the purpose of Nextcaller?"}
              para={
                "The purpose of Nextcaller is to stay focused and engaged during meetings by intelligently blocking calls based on the user's calendar and sending auto-replies while allowing calls from whitelisted contacts to come through."
              }
            />
            <FAQ
              id="2"
              question={"Does the app contain ony in-app purchases?"}
              para={"No. once you claim you get all features."}
            />
            <FAQ
              id="3"
              question={"Can we prevent blocking calls from trusted people?"}
              para={
                "You get a whitelist facility where you can mention the poeple whose calls wont be disrupted by nextcaller"
              }
            />
          </div>
        </div>
        <div className="hero__nextFeature">
          <h1>
            <span style={{ color: "#4395F5", marginRight: 8 }}>
              {" "}
              Nextcaller{" "}
            </span>
            <span></span> Ignore calls Smartly
          </h1>
          <div className="hero__nextFeatureCards">
            <div className="nc">
              <NextCard
                title={"Stress-free environment"}
                para={
                  "Prevent unwanted calls from stressing you during your busy time"
                }
              />
            </div>
            <div className="nc">
              <NextCard
                title={"Ensure your responsibility"}
                para={"Ensure that your callers know, you are busy"}
              />
            </div>
            <div className="nc">
              <NextCard
                title={"Be productive"}
                para={
                  "Increase your productivity by preventing unnecessary calls from disturbing you"
                }
              />
            </div>
            <div className="nc">
              <NextCard
                title={"Give time to yourself and family"}
                para={
                  "Make your leisure time enjoyable by preventing unwanted calls disrupt your family time"
                }
              />
            </div>
          </div>
        </div>
        <div className="hero__testinomial">
          <div className="hero__testinomialText">
            <h1>User Testinomials</h1>
          </div>
          <div className="hero__testinomialCards">
            <TestinomialCard
              src="https://randomuser.me/api/portraits/men/94.jpg"
              name="Badmus adebisi"
              stars="⭐️⭐️⭐️⭐️⭐️"
              test="This is the best DND & blocking app so far. It is very easy to use unlike similar apps that could not even perform at all. So I give my 5 stars to you.. Thanks."
            />
            <TestinomialCard
              src="https://randomuser.me/api/portraits/men/1.jpg"
              name="Stephen Ball"
              stars="⭐️⭐️⭐️⭐️"
              test="Nextcaller is a lifesaver! It keeps me focused by blocking spam calls. I can't imagine my phone without it!"
            />
            <TestinomialCard
              src="https://randomuser.me/api/portraits/men/74.jpg"
              name="Aayush Dhotre"
              stars="⭐️⭐️⭐️⭐️⭐️"
              test="Very nice app. User friendly"
            />
            <TestinomialCard
              src="https://randomuser.me/api/portraits/men/61.jpg"
              name="Ava Ramirez"
              stars="⭐️⭐️⭐️⭐️⭐️"
              test='"NextCaller saves me time and sanity. Best call-blocking app out there!"'
            />
          </div>
        </div>
        <div className="hero__plans">
          <div className="hero__plansText">
            <h1>What will your plan provide you?</h1>
            <div className="plansInfo">
              <p>
                1. Busy mode to block calls automatically during your Busy time
              </p>
              <p>2. Allow you to send auto replies to your callers</p>
              <p>
                3. Allow you to keep your whitelist whose calls won’t be
                disrupted by nextcaller
              </p>
              <p>4. Control the number of calls to be blocked set by you</p>
              <p>
                5. Attain focus and professionalism with other blocking modes:
                Work, Vacation, Spam Blocking Mode
              </p>
            </div>
          </div>
          {/* <div className="hero__nextcaller">
            <div>
              <h1>Nextcaller</h1>
              <span>
                Select your plan and proceed to access the various blocking
                modes for you to not get disturbed by any means during your busy
                time!
              </span>
            </div>
           
          </div> */}
          <div className="hero__prices" ref={paymentSectionRef}>
            <h1>Choose your plan</h1>
            <div className="hero__priceCards">
              {topPlansArray.map((plan, index) => (
                <PayCard
                  key={index}
                  para1={plan.text}
                  highlighted={plan.highlighted}
                  width={200}
                  buttonColor={plan.buttonColor}
                  background={plan.background}
                  height={22}
                  symbol={plan.currencyIcon}
                  paymentLink={plan.paymentLink}
                  price={plan.amountToBePaid}
                  tag={plan.tag}
                  duration={plan.validityStr}
                  recommended={plan.recommended}
                  textColor={plan.textColor}
                  discount={plan.discountTag}
                  cutoff={plan.cutOffAmount}
                />
              ))}
            </div>
          </div>
          <PlayStore />
          <div>
            <Footer />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Hero;
