export const DummyQuestions = [
  {
    prompt: "What describes you?",
    answers: [
      { id: "1", value: "student" },
      { id: "2", value: "Employee" },
      { id: "3", value: "Entrepreneur" },
    ],
    isMultiple: "false",
  },
  {
    prompt: "How much time are you busy in works every day?",
    answers: [
      { id: "4", value: "<3hrs" },
      { id: "5", value: "3.5hrs" },
      { id: "6", value: "5-8hrs" },
      { id: "7", value: "8-10hrs" },
      { id: "8", value: ">10hrs" },
    ],
    isMultiple: "false",
  },
  {
    prompt: "How often do phone calls distract your work?",
    answers: [
      { id: "9", value: "Very often" },
      { id: "10", value: "Fairly" },
      { id: "11", value: "Not much" },
    ],
    isMultiple: "false",
  },
  {
    prompt: "Are you distracted due to phone calls?",
    answers: [
      { id: "12", value: "Yes" },
      { id: "13", value: "No" },
    ],
    isMultiple: "false",
  },
  // {
  //   prompt: "What type of calls cause your distraction the most??",
  //   answers: [
  //     { id: "14", value: "Spams" },
  //     { id: "15", value: "Necessary/work calls" },
  //     { id: "16", value: "Friends/family" },
  //   ],
  //   isMultiple: "true",
  // },
  {
    prompt: "whart type of calls cause your distraction most?",
    answers: [
      { id: "17", value: "Spams" },
      { id: "18", value: "Work calls" },
      { id: "19", value: "Friends/Family" },
    ],
    isMultiple: "true",
  },
  {
    prompt: "How do you deal with calls?",

    answers: [
      { id: "20", value: "Use call blocking app" },
      { id: "21", value: "Silent/Aeroplane mode" },
      { id: "22", value: "Cut call each time" },
    ],
    isMultiple: "true",
  },
  {
    prompt:
      "what properties would you like your call blocking app to have if given a choice?",
    answers: [
      { id: "24", value: "Send autoreplies" },
      {
        id: "25",
        value: "Block unecessary calls while working or during vacation  ",
      },
      { id: "28", value: "Whitelist" },
    ],
    isMultiple: "false",
  },
  {
    prompt:
      "On a scale of 1-10 how much are you frustrated about too much calls during vacation?",
    answers: [
      { id: "26", value: "Yes" },
      { id: "27", value: "No" },
    ],
    isMultiple: "false",
  },
];
